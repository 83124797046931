import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import SEO from '../components/SEO';
import Header from '../components/Header';
import ContentRow from '../components/ContentRow';
import ImgW from '../components/ImgW';


const headerColor = '#79d7f9';

const CasinoPage = ({ data }) => (
  <>
    <SEO
      title="Neosurf Casinos - Online Casinos That Accept Neosurf 2024"
      description="Find out the top-rated online casinos that accept Neosurf deposit method in 2024. List of the reputable Neosurf casinos 2024."
      alternateLanguagePages={[
        {
          path: 'casino',
          language: 'en'
        },
        {
          path: 'casino',
          language: 'fr'
        },
        {
          path: 'casino',
          language: 'de'
        }
      ]}
    />
    <Header backgroundColor={headerColor} />
    <ContentRow backgroundColor={headerColor} padV>
      <div className="grid c-2">
        <div>
          <Img fluid={data.neosurfHeader.childImageSharp.fluid} alt="Best Neosurf Casinos for 2024" />
        </div>
        <div>
          <div className="contentBox textAlignCenter">
            <h1 className="h3 smaller">Top Online Casinos Accepting Neosurf in 2024</h1>
            <p className="textColorDark">When it comes to gambling online for real money, there are all sorts of payment methods you can use to make deposits and withdrawals. From debit and credit cards, to e-wallets and even cryptocurrencies, there are all sorts of payment methods to choose from. One of the <strong>safest and most secure ones is Neosurf</strong>. It’s a voucher-based system that lets you make online payments simply by entering a 10-digit code. When using Neosurf at online casinos, there’s no need to enter sensitive details, nor are your funds linked to any kind of bank account. For these reasons, Neosurf is an ideal payment method to use, especially if you’re after a safer way to make online casino deposits.</p>
          </div>
        </div>
      </div>
    </ContentRow>
    <div className="pageWidth">
      <h2 className="smaller textUppercase">Best Neosurf Casinos</h2>
      <ImgW center fluid={data.neosurfVoucher.childImageSharp.fluid} alt="Screenshot of Neosurf Voucher" />
      <p className="textColorDark">If you decide to use Neosurf to make online payments, you’ll be able to use it at quite a few online casinos. We’ve chosen some of the very <strong>best Neosurf casinos and have listed them below</strong>. Sign up to one of these casinos and if you decide to choose Neosurf as your payment method, you’ll be able to top up your online casino account using Neosurf vouchers. Here’s a look at our favourite Neosurf casinos:</p>
      <div className="grid c-2 bigGap">
        <div>
          <h3 className="textColorDark">LuckyStar Casino</h3>
          <p>Are you feeling lucky? If Lady Luck’s by your side, there’s no better place to play than LuckyStar Casino. Here you’ll find a huge collection of thrilling, entertaining casino games, from video slots and progressive jackpots, to table games, live casino games and many more. A shining star among casinos, this is the place to play if you’re after some big wins!</p>
          <p className="textAlignCenter"><a className="button" href="https://www.luckystar.io/?a_aid=5a6fb263dabe6&amp;a_bid=c6328648">TO LUCKYSTAR CASINO</a></p>
        </div>
        <div>
          <Img fluid={data.lucky.childImageSharp.fluid} alt="LuckyStar – Our favorite Neosurf Casino" />
        </div>
        <div>
          <h3 className="textColorDark">AUSlots Casino</h3>
          <p>If you are looking for the awesome &amp; unique slots, AUSlots is the perfect online gambling destination for you! Followed by its name "<strong>awesome &amp; unique</strong>", AUSlots Casino features over 1000 top-notch casino games playable on PCs, Tablets and all sorts of Mobile devices. Amongst various deposit &amp; withdrawal methods, you will find both Neosurf &amp; MyNeosurf option. Join today and claim some of the hottest Welcome Bonuses around, including famous Cashback option with only 2x wagering requirements!</p>
          <p className="textAlignCenter"><a className="button" href="https://www.auslots.com/?a_aid=5a6fb263dabe6&amp;a_bid=1405c1db">TO AUSlots CASINO</a></p>
        </div>
        <div>
          <Img fluid={data.auslots.childImageSharp.fluid} alt="AUSlots Casino - awesome &amp; unique casino" />
        </div>
        <div>
          <h3 className="textColorDark">BetBTC Casino</h3>
          <p>If you’re a Bitcoin user, you’ll definitely want to check out BetBTC Casino, as it’s a brand new Bitcoin casino! Here you can make payments using the world’s most popular cryptocurrency. With instant deposits and fast withdrawals, plus hundreds of high-quality games by leading providers, excellent customer service, lots of exciting promotions and a rewarding VIP scheme, there are many reasons why BetBTC Casino is a top casino for Bitcoin users.</p>
          <p className="textAlignCenter"><a className="button" href="https://www.betbtc.io/sign-up/a48acf12">TO BETBTC CASINO</a></p>
        </div>
        <div>
          <Img fluid={data.betbtc.childImageSharp.fluid} alt="Perfect for Bitcoin fans – BetBTC Casino" />
        </div>
        <div>
          <h3 className="textColorDark">SpaceLilly Casino</h3>
          <p>SpaceLilly wants you to become her latest recruit and join her on a thrilling online gambling experience like nothing else the universe has to offer! It’s SpaceLilly’s ongoing mission to travel throughout the universe and bring back the best casino games she can find. With well over 1,000 games by some of the very best providers around, not to mention lots of generous promotions, SpaceLilly caters for casino players very well indeed!</p>
          <p className="textAlignCenter"><a className="button" href="http://www.spacelilly.com/?a_aid=5a6fb263dabe6&amp;a_bid=cd4655e5">TO SPACE LILLY</a></p>
        </div>
        <div>
          <Img fluid={data.spaceLilly.childImageSharp.fluid} alt="SpaceLilly Casino accepts Neosurf deposit method" />
        </div>
        <div>
          <h3 className="textColorDark">EmuCasino</h3>
          <p>Named after the largest bird native to Australia, EmuCasino is a user friendly online casino designed with Aussies in mind. Recently relaunched, this online casino caters for pretty much anyone who enjoys playing online casino games. It has over 800 games waiting to be played and plenty of promotional offers you won’t want to miss out on, including regular Casino Races, exciting tournaments that give you the chance to win more than usual from pokies.</p>
          <p className="textAlignCenter"><a className="button" href="https://www.auslots.com/?a_aid=5a6fb263dabe6&amp;a_bid=1405c1db">TO EMU CASINO</a></p>
        </div>
        <div>
          <Img fluid={data.emu.childImageSharp.fluid} alt="Australian online casino – EmuCasino" />
        </div>
        <div>
          <h3 className="textColorDark">777 Slots Bay Casino</h3>
          <p>777slotsbay.com offers the best of online gambling in 2024 with over 3,000 casino games, including 2,000+ slots and virtual sports betting! Whether you are an expert gambler or novice, fantastic bonuses and award-winning customer support will allow you to get the most from this hot new online casino. And with Neosurf as one of their many options for payment transactions – 777slotsbay has made themselves stand out from all other casinos on the web!</p>
          <p className="textAlignCenter"><a className="button" href="https://www.777slotsbay.com/?goft=355fce1f-1701-4bc8-b29d-e3e0ec292198">TO 777 SLOTS BAY CASINO</a></p>
        </div>
        <div>
          <Img fluid={data.sevenSB.childImageSharp.fluid} alt="777 Slots Bay Casino" />
        </div>
        <div>
          <h3 className="textColorDark">777Bay Casino</h3>
          <p>777Bay Casino represents the newest addition to voucher-friendly online casinos launched in 2024. Enjoy huge selection of games at blazing fast loading speeds on both desktop and mobile devices. Selection of over 3000 casino games from 47 game providers will make even the pickiest players happy!  And if it’s not your lucky day, 777Bay Cashback offers could make a difference!</p>
          <p className="textAlignCenter"><a className="button" href="http://www.777bay.com/?a_aid=5a6fb263dabe6">TO 777BAY CASINO</a></p>
        </div>
        <div>
          <Img fluid={data.sevenB.childImageSharp.fluid} alt="777Bay Casino Logo" />
        </div>
      </div>
    </div>
    <ContentRow backgroundColor="#eee" padV>
      <h2 className="smaller textUppercase">Benefits of Using Neosurf in Online Casinos</h2>
      <p className="textColorDark">Why should you use Neosurf when there are plenty of other payment methods accepted at online casinos? What sets Neosurf apart from others is the fact that, when using it online, <strong>you’re not required to input any personal or banking details</strong> at all. The only two things you need to have funds transferred from your Neosurf voucher are the code on the voucher and an online casino account that’s ready to receive the funds. This makes Neosurf considerably safer than other payment methods, which require you to enter details for you to use them.</p>
      <ImgW center fluid={data.neosurfCity.childImageSharp.fluid} alt="Safe &amp; secure payment method" />
      <p>Another key advantage is that Neosurf is very <strong>easy to use</strong> indeed. Just buy a voucher, input the code and then you’re done. There’s just a few simple steps to take.</p>
    </ContentRow>
    <div className="pageWidth">
      <h2 className="textColorDark">How to Join and Use Neosurf</h2>
      <p>Using Neosurf couldn’t be any easier. All it takes is a few simple steps and then you’ll have the money in your online casino account ready to be used for online gambling. Here’s what you need to do to gamble online for real money with Neosurf:</p>
      <ul className="textColorDark">
        <li>Visit Neosurf’s official website and visit the WHERE TO FIND NEOSURF? page to locate your nearest Neosurf retailer</li>
        <li>Visit a Neosurf retailer and purchase a Neosurf voucher; there’s a range of denominations available</li>
        <li>You’ll receive a receipt with a 10-digit code on it; keep both the receipt and the code safe</li>
        <li>Visit the website you want to use Neosurf at; choose to deposit with Neosurf and simply enter the 10-digit code on your receipt</li>
        <li>Any money left on your voucher can still be spent or even transferred to another Neosurf voucher</li>
      </ul>
      <p>That’s all there is to using Neosurf online. As you can see, it’s very straightforward to use. With more than 135,000 retailers worldwide selling Neosurf vouchers, you shouldn’t have to travel too far to find your nearest retailer. Once you’ve got your code, you’ll be able to use it at more than 20,000 sites, including a number of well known gaming, gambling and entertainment sites.</p>
      <h2 className="textColorDark">FAQs</h2>
      <h3 className="textColorDark">What sort of retailers sell Neosurf vouchers?</h3>
      <p>Neosurf vouchers are readily available for purchase at all sorts of convenient, everyday locations. These include supermarkets, news stands, petrol stations, convenience stores and internet cafes.</p>
      <h3 className="textColorDark">Can Neosurf be used to top up other payment methods?</h3>
      <p>With Neosurf vouchers, you can add funds to other payment methods, such as e-wallets and prepaid cards. Doing so works in pretty much the same way as making online payments with the voucher, so you’ll need to keep a hold of your receipt and 10-digit code.</p>
      <h3 className="textColorDark">Can I check the status of my voucher online?</h3>
      <p>Yes you can. Simply visit the Neosurf website and head to the MY CARD section. Then just input your 10-digit code and you’ll get to see how much credit you have left. If you still have some funds available, you can transfer them to other Neosurf vouchers. </p>
      <h3 className="textColorDark">What’s MyNeosurf?</h3>
      <p>MyNeosurf is an e-wallet where you can store all of your Neosurf voucher codes in one secure place online. Not only can you top up your MyNeosurf account with Neosurf vouchers, you can also top it up with credit cards, debit cards and bank transfers. With MyNeosurf you can make online payments and, if you decide to get a Neocash Mastercard, you can make withdrawals from your MyNeosurf account at ATMs. </p>
      <h3 className="textColorDark">Can I make withdrawals using Neosurf?</h3>
      <p>The only real downside to Neosurf is that, because it’s a voucher-based system, it can’t be used for making withdrawals. If you top up your online casino using Neosurf vouchers, you’ll have to have some other payment method ready for when you want to make withdrawals. </p>
    </div>
  </>
)

export default CasinoPage;

export const pageQuery = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    neosurfHeader: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "casino/Best-Neosurf-Casinos-for-2020.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 899,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    neosurfVoucher: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "casino/Screenshot-of-Neosurf-Voucher-2.png"}) {
      childImageSharp {
        fluid(
          maxWidth: 700,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    neosurfCity: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "casino/Safe-secure-payment-method.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 700,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    lucky: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "casino/LuckyStar-Our-favorite-Neosurf-Casino.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 700,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    auslots: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "casino/AUSlots-Casino-home.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 940,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    betbtc: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "casino/Perfect-for-Bitcoin-fans-BetBTC-Casino.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 700,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    spaceLilly: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "casino/SpaceLilly-Casino-accepts-Neosurf-deposit-method.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 700,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    emu: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "casino/Australian-online-casino-EmuCasino.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 700,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sevenSB: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "casino/777slotsbay-accepts-Neosurf-deposit-method.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 700,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sevenB: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "casino/777bay-accepts-Neosurf-deposit-method.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 700,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
